


























import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class ErrorLayout extends Vue {
  @Prop({
    type: Object,
    default: null,
  })
  error!: {
    statusCode: number;
    message: string;
  }

  get isDev() {
    return process.env.NODE_ENV !== 'production'
  }
}
